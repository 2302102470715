import React from "react";
import img_hamburger from "../img/hamburger.png";
import img_userIcon from "../img/user.png";
import img_webMLBrand from "../img/web-ML_brand.png";
import img_blog from "../img/blog.png";

const Head = () => {
  return (
    <div className="grid grid-flow-col p-2 m-2 shadow-lg bg-gray-50">
      <div className="flex col-span-1">
        <img className="h-8 mx-5" alt="hamburger-menu" src={img_hamburger} />
        <a href="/" className="flex">
          <img
            className="h-8 w-20 mx-2"
            alt="webML-logo"
            src={img_webMLBrand}
          />
        </a>
      </div>
      <div className="col-span-10 px-10">
        <input
          className="w-1/2 border border-gray-400 p-2 rounded-l-full"
          type="text"
        ></input>
        <button className="border border-gray-400 p-2 rounded-r-full bg-slate-200">
          🔏🔐🔓
        </button>
      </div>
      <div className="flex col-span-1">
        <img className="h-8 mx-10" alt="user" src={img_blog} />
        <img className="h-8" alt="user" src={img_userIcon} />
      </div>
    </div>
  );
};

export default Head;
