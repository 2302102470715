import React from "react";

const SubjectMenuItems = () => {
  return (
    <div className="p-5 shadow-lg w-48">
      <ul>
        <li>Data Science</li>
        <li>Python</li>
        <li>Statistics</li>
        <li>NumPy</li>
        <li>Pandas</li>
        <li>WebScrapping</li>
      </ul>
    </div>
  );
};

export default SubjectMenuItems;
