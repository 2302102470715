import React from "react";
import SubjectMenuItems from "./SubjectMenuItems";

const Sidebar = () => {
  return (
    <div>
      <SubjectMenuItems />
    </div>
  );
};

export default Sidebar;
