import React from "react";
import ContentHeadDecs from "./ContentHeadDecs";

const MainContainer = () => {
  return (
    <div className="col-span-11">
      <ContentHeadDecs />
    </div>
  );
};

export default MainContainer;
